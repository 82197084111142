












import { Component, Prop, Vue } from 'vue-property-decorator'
import { Loader } from './Loader.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<FullscreenLoader>({ name: 'FullscreenLoader', components: { Loader } })
export class FullscreenLoader extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isLoading!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isTransparent!: boolean
}

export default FullscreenLoader
