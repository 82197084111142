


import { AnyObject } from '@movecloser/front-core'
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'AbstractDrawer' })
export class AbstractDrawer<Payload extends AnyObject = AnyObject> extends Vue {
  @Prop({ type: Function, required: true })
  public readonly closeCb!: () => void

  @Prop({ type: Boolean, required: false, default: true })
  public readonly hasHeader!: boolean

  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly payload!: Payload

  /**
   * Determines whether the payload has been injected.
   */
  public get hasPayload (): boolean {
    return typeof this.payload !== 'undefined' && Object.keys(this.payload).length > 0
  }

  /**
   * Closes the modal.
   */
  public close (result: boolean = false): void {
    if (this.hasPayload && typeof this.payload.onClose === 'function') {
      this.payload.onClose(result)
      setTimeout(() => this.closeCb(), 500)
      return
    }

    this.closeCb()
  }
}

export default AbstractDrawer
