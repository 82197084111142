








import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<Loader>({ name: 'Loader' })
export class Loader extends Vue {
  @Prop({ required: false, type: String, default: () => '20' })
  public size!: string
}

export default Loader
